.top-nav {
    display: flex;
    background-color: var(--white);
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: var(--box-shadow);
    position: fixed;

    h2 {
        margin: 0 auto;
    }

    span {
        font-size: 16px;
        // --tw-scale-x: .5;
        // --tw-scale-y: .5;
        // transform: translate(0, 0 rotate(0)) skew(0) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        cursor: pointer;

        .bk-btn {
            color: var(--green);
        }

        img {
            padding: 8px;
        }

        img:hover {
            background-color: var(--main-background);
            border-radius: 36%;
        }
    }

    .dropbox {
        width: 100px;
        padding: 5px;
        position: absolute;
        right: 16px;
        bottom: -61px;
        background-color: var(--white);
        border: 1px solid var(--border);
        border-radius: 8px;

        a {
            color: #000;
        }

        p {
            margin: 0;
            padding: 3px 5px;
            font-size: 14px;
            border-radius: px;
            cursor: pointer;

            &:hover {
                background-color: var(--green);
                color: var(--white);
            }
        }

    }

    .dropdown-container {
        .dd-dropdown {
            position: relative;
            margin: 0px 3px;
            width: 100%;

            .dropdown-icon {
                color: var(--bg-orange);
                position: absolute;
                right: 5px;
                padding: 2px;
                width: 16px;
                height: 16px;
            }

            .dropdown {
                width: 100% !important;
                border-radius: 7px;
                border: 1px solid rgba(223, 223, 223, 1);
                background-color: var(--bg-white);
                padding: 0px 4px;
            }

            .dropdown-header {
                font-size: 14px;
                padding: 5px;
                cursor: pointer;
                display: flex;
                color: var(--dark-purple-bg);
                justify-content: space-between;
                align-items: center;
            }

            .dropdown-body {
                border-top: 1px solid #e5e8ec;
                // display: none;
                color: var(--dark-purple-bg);
                border-radius: 10px;
                box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.11);
                width: 100%;
                background-color: var(--bg-white);
                position: absolute;
                margin-top: 10px;
                padding-top: 7px;
                padding-bottom: 6px;
                z-index: 4;
            }

            .dropdown-body.open {
                display: block;
            }

            .dropdown-item {
                padding: 5px 10px;
                font-size: 14px;
                color: var(--dark-purple-bg);
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .dropdown-item:hover {
                cursor: pointer;
                background-color: rgba(228, 228, 228, 1);
            }

            .dropdown-item-dot {
                opacity: 0;
                color: #91a5be;
                transition: all 0.2s ease-in-out;
            }

            .dropdown-item-dot.selected {
                opacity: 1;
            }
        }
    }
}

@media only screen and (min-device-width: 500px) {
    .top-nav {
        width: 500px;
    }
}

.dashboard-nav {
    display: flex;
    background-color: var(--white);
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: var(--box-shadow);
    position: fixed;

    h2 {
        margin: 0;
        font-weight: 600;
    }

    .nav-contents {
        display: flex;
        align-items: center;

        a {
            color: #000;
            margin-right: 25px;
            margin-top: 8px;
        }
        svg {
            color: #000;
            margin-right: 25px;
            margin-top: 8px;
        }

        span {
            font-size: 20px;
        }

        .img-box {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid var(--light-blue);
            margin-left: 20px;
            cursor: pointer;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        
    }
   
}

@media only screen and (min-device-width: 500px) {
    .dashboard-nav {
        width: 500px;
    }
}


.mobile-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    //give nav it's own compsite layer
    will-change: transform;
    transform: translateZ(0);

    display: flex;

    height: 50px;

    box-shadow: 0 -2px 5px -2px #333;
    background-color: #fff;

    &__item {
        flex-grow: 1;
        text-align: center;
        font-size: 12px;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__item--active {
        //dev
        color: red;
    }

    &__item-content {
        display: flex;
        flex-direction: column;

        svg {
            width: 20px;
            margin: 0 auto;

            &:hover {
                color: var(--green);
            }
        }
    }
}

@media only screen and (min-device-width: 500px) {
    .mobile-bottom-nav {
        width: 500px;
        margin: 0 auto;
    }
}