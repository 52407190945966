.notifications {

    .pr-container {
        width: 100%;
        background-color: var(--white);

        .prc-contents {
            padding: 10px 15px;
            border-bottom: 1px solid var(--border-bottom-color);
            display: flex;
            cursor: pointer;


            .img-box {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid var(--light-blue);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .prc-details {
                margin-left: 20px;
                width: 73%;

                h5 {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 700;
                }

                h6 {
                    margin: 0;
                    font-size: 14px;
                }

                span {
                    float: right;
                    color: var(--green);
                    font-weight: 500;
                }
            }

            &:hover {
                background-color: var(--green);
                color: var(--white);

                .prc-details {

                    h5 {
                        color: var(--white);
                    }

                    h6 {
                        color: var(--white);
                    }

                    span {
                        color: var(--white);
                    }

                }
            }
        }
    }
}