.user-transactions-container {

    // background-color: var(--main-background);
    nav {
        span {
            .bk-btn {
                color: var(--blue);
            }
        }
    }

    main {
        padding: 60px 10px 20px;
        background-image: url(../../assets/images/bg1.jpeg);
        background-position: center center;
        background-repeat: no-repeat;
        /* background-attachment: fixed; */
        background-size: 190% 100%;
        height: 100vh;

        .example {
            padding-top: 100px;

            span {
                i {
                    background-color: var(--blue) !important;
                }
            }
        }
    }
}

@media only screen and (min-device-width: 500px) {
    .admin-profile-container {
        width: 500px;
        margin: 0 auto;
    }

    .user-transactions-container {
        width: 500px;
        margin: 0 auto;
    }
}

.pr-container {
    width: 100%;
    background-color: var(--white);
    border-radius: 5px;

    .ref-link-title{
        text-align: center;
        margin-bottom: 0px;
        font-weight: 600;
        margin-top: 10px;
    }
    .ref-link{
        text-align: center;
        margin-bottom: 3px;
    }

    .prc-contents {
        padding: 10px 15px;
        border-bottom: 1px solid var(--border-bottom-color);
        display: flex;
        cursor: pointer;
        transition: none !important;


        .img-box {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid var(--light-blue);

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .prc-details {
            margin-left: 20px;

            h5 {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
            }

            h6 {
                margin: 0;
                font-size: 14px;
            }
        }

        &:hover {
            background-color: var(--green);
            color: var(--white);

            .prc-details {

                h5 {
                    color: var(--white);
                }

                h6 {
                    color: var(--white);

                }
            }
        }
    }
}

.change-password-nav {
    padding: 20px 20px 0;
    display: flex;
    align-items: center;

    h3 {
        color: var(--blue);
        text-align: center;
        margin: 0 auto;
    }
}

.reveal2 {
    position: absolute;
    top: 35px;
    right: 15px;
}

.successTxt{
    margin-bottom: 3px;
    color: var(--green);
}

