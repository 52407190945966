.profile-container {
    background-color: var(--white);
    text-align: center;
    padding: 10px;
    margin-bottom: 16px;
    box-shadow: var(--box-shadow-sm);

    .pc-detail-box {
        text-align: center;
        padding: 10px;

        .img-box {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 1px solid var(--light-blue);
            margin-bottom: 15px;
            overflow: hidden;
            margin: 0 auto;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }


        h4 {
            margin-bottom: 0px;
            font-size: 18px;
            font-weight: 700;
            margin-top: 20px;

        }

        h6 {
            font-size: 14px;
            margin-bottom: 3px;
            font-weight: 200;
        }

        span {
            background-color: var(--light-green);
            color: var(--white);
            padding: 0 12px;
            font-size: 16px;
            border-radius: 4px;
        }
    }
}

.profile-wallets {
    padding: 15px;
    background-color: var(--white);
    margin-bottom: 16px;
    box-shadow: var(--box-shadow-sm);

    h3 {
        font-size: 18px;
        font-weight: 500;
        font-family: "Varela Round";
    }

    .pw-amount {
        background-color: var(--main-background);
        border-radius: 10px;
        padding: 20px 25px;
        text-align: center;
        margin-bottom: 8px;

        p {
            margin: 0;
            color: var(--green);
            font-size: 45px;
            font-weight: 700;
        }
    }

    .pw-type {
        display: flex;
        justify-content: space-between;

        .pw-type-box {
            padding: 10px;
            background-color: var(--main-background);
            border-radius: 7px;
            font-weight: 400;
            cursor: pointer;
            font-size: 0.875rem;
            width: 97px;
            line-height: 1;
            text-align: center;

            &:hover {
                background-color: var(--green);
                color: var(--white);
            }
        }

        .active {
            background-color: var(--green);
            color: var(--white);
        }
    }
}

.profile-referrals {
    .pr-header {
        padding: 10px 15px;
        background-color: var(--white);
        margin-bottom: 16px;
        // box-shadow: var(--box-shadow-sm);
        // box-shadow: 0 0 #000, 0 0 #000, 0 0 #000, 0 0 #000, 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);

        h3 {
            font-size: 18px;
            margin-bottom: 0;
            font-weight: 500;
            font-family: "Varela Round";
        }
    }

    .pr-container {
        width: 100%;
        background-color: var(--white);

        .prc-contents {
            padding: 10px 15px;
            border-bottom: 1px solid var(--border-bottom-color);
            display: flex;
            cursor: pointer;
            transition: none !important;


            .img-box {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid var(--light-blue);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .prc-details {
                margin-left: 20px;

                h5 {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 600;
                }

                h6 {
                    margin: 0;
                    font-size: 14px;
                }
            }

            &:hover {
                background-color: var(--green);
                color: var(--white);

                .prc-details {

                    h5 {
                        color: var(--white);
                    }

                    h6 {
                        color: var(--white);

                    }
                }
            }
        }
    }
}

.edit-profile-container {
    background-color: var(--white);
    padding: 10px;
    margin-bottom: 16px;

    .epc-detail-box {
        padding: 10px;

        .img-box {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 1px solid var(--light-blue);
            overflow: hidden;
            margin: 0 auto 20px;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .input-group {
        margin-bottom: 16px;

        .form-input {
            margin-top: 5px;
            width: 100%;
            background: #ffffff;
            border: 1px solid #5ede99;
            box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
            border-radius: 3px;
            height: 42px;
            padding-left: 0.2rem;
        }
    }
    .successTxt{
        margin-bottom: 3px;
        color: var(--green);
    }

    .btn-btn {
        background-color: var(--green);
        color: var(--white);
        font-size: 14px;
        padding: 10px 15px;
        border-radius: 5px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        span {
            i {
                background-color: var(--white);
            }
        }
    }

}