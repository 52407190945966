.admin-profile-container {
    // background-color: var(--main-background);

    main {
        padding: 60px 16px 20px;
        background-image: url(../../assets/images/bg1.jpeg);
        background-position: center center;
        background-repeat: no-repeat;
        /* background-attachment: fixed; */
        background-size: 190% 100%;
        height: 100vh;

        .example {
            padding-top: 100px;

            span {
                i {
                    background-color: var(--green);
                }
            }
        }
    }
}

@media only screen and (min-device-width: 500px) {
    .admin-profile-container {
        width: 500px;
        margin: 0 auto;
    }
}