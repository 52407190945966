@media (max-width: 1000px) {
  body {
    font-size: 15px;
  }
}

body {
  font-family: "Quicksand", sans-serif;
}
.containers {
  width: 85%;
  margin: auto !important;
}
.hero {
  height: 490px;
}
.hero h1 {
  line-height: 60px;
  font-size: 55px;
  font-weight: 600;
  font-family: "Josefin Sans";
}
@media (max-width: 700px) {
  .hero {
    height: 400px;
  }
  .hero h1 {
    line-height: 30px !important;
    font-size: 25px !important;
    font-weight: 800;
  }
}
@media (max-width: 1000px) {
  .hero {
    height: 400px;
  }
  .hero h1 {
    line-height: 45px;
    font-size: 37px;
    font-weight: 800;
  }
  .login {
    text-align: center;
    margin-top: 40px;
  }
  .nav-item {
    padding-top: 10px;
  }
}
.hero-4 {
  margin-top: 120px;
  width: 50%;
}
.hero-1 {
  width: 65% !important;
  margin-top: 80px;
  background-color: rgba(255, 255, 255, 0.5);
}
.hero-2 {
  width: 40% !important;
  margin-top: 40px;
}
.carousel-1 {
  background-image: url("../../assets/images/cryptocurrency-nvestors-.jpg");
  background-size: 100% 100%;
  height: 460px;
  background-position: top right;
  background-repeat: no-repeat;
  overflow: hidden;
}
.carousel-2 {
  background-image: url("../../assets/images/five.png");
  background-size: 50% 135%;
  height: 460px;
  background-position: right;
  background-repeat: no-repeat;
}
.carousel-3 {
  background-image: url("../../assets/images/telegram.png");
  background-size: 50% 135%;
  height: 460px;
  background-position: right;
  background-repeat: no-repeat;
}
.carousel-4 {
  background-image: url("../../assets/images/istockphoto-467907011-612x612.jpg");
  background-size: contain;
  height: 460px;
  background-position: top right;
  background-repeat: no-repeat;

  padding-left: 20px;
  overflow: hidden;
}
@media (max-width: 700px) {
  .hero-1 {
    width: 100% !important;
    margin-top: 80px;
    background-color: rgba(255, 255, 255, 0);
  }
  .carousel-1 {
    background-image: linear-gradient(
        to bottom,
        rgba(225, 225, 225, 0.5),
        rgba(225, 225, 225, 0.5)
      ),
      url("../../assets/images/cryptocurrency-nvestors-.jpg");
    background-size: 100% 105%;
    height: 390px;
    text-align: center;
    background-position: top right;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .carousel-2 {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      url("../../assets/images/five.png");
    background-size: 100% 105% !important;
    background-position: 0px -50px !important;
    height: 390px;
  }
  .carousel-3 {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      url("../../assets/images/telegram.png");
    background-size: 85% 105% !important;
    background-position: 50px -50px !important;
    height: 380px;
    opacity: 1;
  }
  .carousel-4 {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      url("../../assets/images/istockphoto-467907011-612x612.jpg");
    background-size: 100% 80%;
    height: 380px;
    background-position: 50px;
    background-repeat: no-repeat;
    padding-left: 20px;
    overflow: hidden;
  }
}
@media (min-width: 701px) and (max-width: 1000px) {
  .hero-1 {
    width: 100% !important;
    margin-top: 80px;
    background-color: rgba(255, 255, 255, 0);
  }
  .carousel-1 {
    background-image: linear-gradient(
        to bottom,
        rgba(225, 225, 225, 0.5),
        rgba(225, 225, 225, 0.5)
      ),
      url("../../assets/images/cryptocurrency-nvestors-.jpg");
    background-size: 100% 105%;
    height: 390px;
    text-align: center;
    background-position: top right;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  .carousel-2 {
    background-size: 60% 125%;
    height: 390px;
  }
  .carousel-3 {
    background-size: 55% 125%;
    height: 380px;
    opacity: 1;
  }
  .carousel-4 {
    background-image: url("../../assets/images/istockphoto-467907011-612x612.jpg");
    /* background-size: 150% 120%;
    height: 380px; */
    background-size: 100% 80%;
    height: 380px;
    background-position: 50px;
    background-repeat: no-repeat;

    padding-left: 20px;
    overflow: hidden;
  }
}
.hero-3 {
  width: 30% !important;
  margin-top: 60px;
}
.carousel-indicators button {
  background-color: rgba(83, 121, 239, 0.8) !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  padding: 0px 10px 0px;
}

.section {
  margin-top: 60px;
}
.invest-head {
  width: 50%;
  margin: auto;
  margin-bottom: 60px;
}
.invest-body {
  width: 85%;
  margin: auto;
  text-align: justify;
}
.bg-ligh {
  background: rgb(186, 243, 255);
  background: radial-gradient(
    circle,
    rgba(186, 243, 255, 0.9023984593837535) 30%,
    rgba(64, 203, 236, 1) 83%
  );
}
.counts {
  font-size: 40px;
  border-radius: 10px;
  font-weight: bold;
  /* background: rgb(218, 210, 210);
  background: linear-gradient(
    0deg,
    rgba(218, 210, 210, 0.3) 0%,
    rgba(120, 112, 112, 0.3) 100%
  ); */
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px 0px;
}
.counts h4 {
  font-weight: bold;
}
@media (max-width: 1000px) {
  .invest-head {
    width: 95%;
  }
  .counts {
    font-size: 23px;
    font-weight: bold;
    padding: 10px 0px;
  }
  .counts h4 {
    font-size: 16px;
  }
  .review {
    width: 80% !important;
    font-size: 12px !important;
  }
  .review .image {
    width: 30% !important;
  }
  .socials {
    display: flex;
    justify-content: center;
  }
}
/* testimonials section for stlying */
/* testimonials section for stlying */
.text-width {
  width: 75%;
}
.test-bg {
  /* background-image: url("../../assets/images/caros.webp"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-color: var(--main-background);
}
@media (max-width: 1000px) {
  .text-width {
    width: 95%;
  }
}
.feature {
  margin-top: 60px !important;
}
.review {
  width: 50%;
  font-size: 14px !important;
  display: flex;
  justify-content: center;
}
.review .image {
  width: 25%;
}
.review .image img {
  border-radius: 50%;
}
.connect {
  background-color: rgba(186, 200, 214, 0.3);
}
.partner {
  height: 150px;
  overflow: hidden;
}
.robo-div {
  width: 45% !important;
  margin: auto;
}
.spon {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px !important;
}
@media (max-width: 1000px) {
  .part-img {
    position: relative;
    left: -30px;
  }
}

/* read news */
/* read news */
/* read news */
.text-head h2 {
  font-weight: 600;
  margin: 0px 0px 30px;
}
.read {
  width: 100%;
  height: 300px;
  border-radius: 10px;
}
.date {
  font-size: 12px;
}
@media (max-width: 1000px) {
  .l-s {
    display: none !important;
  }
  .read {
    width: 80%;
    height: 300px;
    border-radius: 10px;
    margin: auto;
  }
  .carousel-control-prev {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    background-color: black !important;
  }
  .carousel-control-next {
    width: 60px !important;
    height: 60px !important;
    border-radius: 150%;
    background-color: black !important;
  }
  .lefts,
  .rights {
    background: rgb(186, 243, 255);
    background: radial-gradient(
      circle,
      rgba(186, 243, 255, 0.9023984593837535) 30%,
      rgba(64, 82, 236, 1) 83%
    );
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
  }
}
.read-1 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("../../assets/images/read-bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: white;
}
.read-2 {
  background-image: url("../../assets/images/read-bg-2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: white;
}
.read-3 {
  background-image: url("../../assets/images/read-bg-3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: white;
}
.links ul {
  list-style: none;
}
.links .link {
  margin-left: px;
  font-weight: bold;
}
.links ul li a {
  text-decoration: none;
  color: inherit;
}
.links ul li {
  padding: 6px 0px;
  margin: 0px 0px;
}
@media (max-width: 1000px) {
  .partner {
    height: 150px;
    overflow: hidden;
  }
}

/* animation css styling */
.left {
  position: relative;
  transform: translateX(200px);
  opacity: 0;
  transition: 2s all ease;
  z-index: 0;
}
.left.active {
  opacity: 1;
  transform: translateX(0px);
}
.right {
  position: relative;
  transform: translateX(-200px);
  opacity: 0;
  transition: 2s all ease;
  z-index: 0;
}
.right.active {
  opacity: 1;
  transform: translateX(0px);
}
.down {
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease-in;
  z-index: 0;
}
.down.active {
  opacity: 1;
  transform: translateY(0px);
}

.nav-bars {
  outline: none;
}
.nav-bars:focus {
  outline: none;
  box-shadow: none !important;
}

.nav-link2 {
  line-height: 1 !important;
  border: none !important;
}
.nav-link2:hover {
  color: var(--blue) !important;
}

.home-card {
  transition: 0.3s ease-in-out;
  padding-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  /* box-shadow: 0 0 0 1px rgb(53 72 91 / 7%), 0 2px 2px rgb(0 0 0 / 1%),
    0 4px 4px rgb(0 0 0 / 2%), 0 10px 8px rgb(0 0 0 / 3%),
    0 15px 15px rgb(0 0 0 / 3%), 0 30px 30px rgb(0 0 0 / 4%),
    0 70px 65px rgb(0 0 0 / 5%); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);  */
  border: 1px solid var(--border);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.josefin {
  font-family: "Josefin Sans";
}
