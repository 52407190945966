.dashboard-container {
    background-image: url(../../../assets/images/bg1.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: 190% 100%;

    .dc-box {
        padding: 15px;
        box-shadow: var(--box-shadow-sm);
        margin-bottom: 16px;

        .btn-items {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            gap: 16px;
            margin-bottom: 10px;

            .btn-update {
                border-radius: 5px;
                box-shadow: var(--box-shadow-sm);
                background-color: var(--white);
                padding: 8px 15px;
                cursor: pointer;
                flex: 1 1 auto;
                font-weight: 600;

                &:hover {
                    background-color: var(--green);
                    color: var(--white);
                }
            }
        }

        .search-input {
            background-color: rgba(241, 245, 249, 1);
            border: 1px solid rgba(229, 231, 2359, 1);
            padding: 7px 20px;
            width: 100%;
            border-radius: 30px;
            font-weight: 100;

            &::placeholder {
                font-family: 'Quicksand';

            }
        }


    }

    .dashboard-users {
        padding: 0 16px;
        .du-header {
            padding: 10px 15px;
            background-color: var(--white);
            margin-bottom: 10px;
            box-shadow: var(--box-shadow-sm);
            // box-shadow: 0 0 #000, 0 0 #000, 0 0 #000, 0 0 #000, 0 4px 6px -1px rgb(0 0 0 ), 0 2px 4px -2px rgb(0 0 0 );

            h3 {
                font-size: 16px;
                margin-bottom: 0;
                font-weight: 600;
            }
        }

        .du-container {
            width: 100%;
            background-color: var(--white);
            padding-bottom: 55px;

            .example {
                padding-top: 100px;

                span {
                    i {
                        background-color: var(--green);
                    }
                }
            }

            .prc-contents {
                padding: 10px 15px;
                border-bottom: 1px solid var(--border-bottom-color);
                display: flex;
                cursor: pointer;
                align-items: center;

                .img-box {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 1px solid var(--light-blue);

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                .prc-details {
                    margin-left: 20px;
                    align-self: flex-start;

                    h5 {
                        margin-bottom: 0;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    h6 {
                        margin: 0;
                        font-size: 14px;
                    }
                }

                &:hover {
                    background-color: var(--green);
                    color: var(--white);

                    .prc-details {

                        h5 {
                            color: var(--white);
                        }

                        h6 {
                            color: var(--white);

                        }
                    }
                }

                .del-trash {
                    margin-left: auto;
                }
            }
        }
    }

    element.style {
        animation: 300ms ease 0s 1 normal none running react-responsive-modal-modal-in;
    }



}

.react-responsive-modal-modal {
    border-radius: 10px;
    padding: 0 !important;

    .modal-header {
        border-bottom: 1px solid var(--border) !important;
        padding: 10px 16px;
        margin-bottom: 8px;

        h3 {
            color: #000;
            margin-bottom: 0;
        }
    }

    .modal-body {
        padding: 10px 16px;

        .successTxt{
            margin-bottom: 3px;
            color: var(--green);
        }


        .btn-btn {
            background-color: var(--green);
            color: var(--white);
            font-size: 14px;
            padding: 8px 15px;
            border-radius: 5px;
            margin-top: 15px;
            margin-bottom: 20px;
            float: right;
            cursor: pointer;
            width: 100px;
            span {
                i {
                    background-color: var(--white);
                }
            }
        }
    }
}