.user-profile-referrals {
    width: 90%;
    margin: 0 auto;

    .upr-header-top {
        padding: 5px;
        background-color: var(--white);
        margin-bottom: 10px;
        border-radius: 5px;
        text-align: center;

        p {
            font-family: 'Poppins';
        }

        .ref-link-title {
            text-align: center;
            margin-bottom: 0px;
            font-weight: 600;
        }

        .ref-link {
            text-align: center;
            margin-bottom: 3px;
            font-size: 12px;
        }
    }

    .upr-header {
        padding: 10px 15px;
        background-color: var(--white);
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        cursor: pointer;
        // box-shadow: var(--box-shadow-sm);
        // box-shadow: 0 0 #000, 0 0 #000, 0 0 #000, 0 0 #000, 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);

        h3 {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 600;
        }

        h2 {
            font-size: 18px;
            margin-bottom: 0;
            font-weight: 600;
        }
    }

    .pr-container {
        width: 100%;
        background-color: var(--white);
        border-radius: 5px;

        .prc-contents {
            padding: 10px 15px;
            border-bottom: 1px solid var(--border-bottom-color);
            display: flex;
            cursor: pointer;
            transition: none !important;


            .img-box {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid var(--light-blue);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .prc-details {
                margin-left: 20px;

                h5 {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 600;
                }

                h6 {
                    margin: 0;
                    font-size: 14px;
                }
            }

            &:hover {
                background-color: var(--green);
                color: var(--white);

                .prc-details {

                    h5 {
                        color: var(--white);
                    }

                    h6 {
                        color: var(--white);

                    }
                }
            }
        }
    }
}