.messages-container {
    nav {
        background-color: var(--green);
        width: 100%;

        .prc-contents {
            padding: 10px 15px;
            border-bottom: 1px solid var(--border-bottom-color);
            display: flex;
            cursor: pointer;
            align-items: center;

            .img-box {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid var(--light-blue);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .prc-details {
                margin-left: 20px;
                align-self: flex-start;

                h5 {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--white);
                }

                h6 {
                    margin: 0;
                    font-size: 14px;
                    color: var(--white);
                }
            }


        }
    }

    main{
        padding: 10px;
    }


}