/* @media only screen and (min-device-width: 320px) and (max-device-width: 480px) { */
/* styles */
.signin-container {
  background: #f7f7f7;
  height: 100vh;
  display: flex;
  align-items: center;
  font-family: "Mulish";
  background-image: url(../../assets/images/img4.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: 190% 100%;
  
}
.signin-row {
  width: 92%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.84);
  padding: 15px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.signin-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.signin-header .signin-title {
  font-weight: 900;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #0b5ba1;
}

.signin-body {
  margin-top: 10px;
}

.signin-header .signin-body {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #0b5ba1;
}
.signin-form label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 5px;
}
.signin-email,
.signin-password {
  margin-bottom: 10px;
}
input[type="text"],
input[type="password"] {
  width: 100%;
  background: #ffffff;
  border: 1px solid #5ede99;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-radius: 3px;
  height: 42px;
  padding-left: 0.2rem;
}
input:focus {
  outline: none;
}
input::placeholder {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #bebebe;
}
.signin-forgot-password {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}
.forgot-password {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #efbe24;
  padding-bottom: 1rem;
  padding-top: 2rem;
}
.signin-submit {
  padding: 11px 0px;
  text-align: center;
  background: #0b5ba1;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
}
.signin-link-to-register {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #0b5ba1;
  padding-top: 1.5rem;
  font-weight: 600;
}
.signin-link-to-register a {
  color: #0b5ba1;
  text-decoration: none;
}
.signin-link-to-register a:active {
  text-decoration: none;
  color: red;
}
/* } */

.err-txt {
  color: var(--red);
  text-align: center;
}
@media only screen and (min-device-width: 500px) {
  .signin-container {
    width: 500px;
    margin: 0 auto;
  }
}
