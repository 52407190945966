/* @media only screen and (min-device-width: 320px) and (max-device-width: 480px) { */
/* Styles */
.user-profile-container {
  background: #e5e5e5;
  width: 100%;
  padding-bottom: 20px;
  background-image: url(../../assets/images/bg1.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: 190% 100%;
  height: 100vh;
}

.nav-link {
  font-size: 16px;
  margin: 7px 0;
  line-height: 3;
  color: #000;
  border-bottom: 1px solid var(--border-bottom-color);
  transition: none !important;
}
.nav-link-blue {
  font-size: 16px;
  margin: 7px 0;
  line-height: 3;
  color: #000;
  border-bottom: 1px solid var(--border-bottom-color);
  transition: none !important;
}
.nav-link:hover {
  /* font-size: 18px; */
  color: var(--green);
  font-weight: 700;
  transition: none !important;
}
.nav-link-blue:hover {
  /* font-size: 18px; */
  color: var(--blue);
  font-weight: 700;
  transition: none !important;
}

.ant-drawer-body {
  padding: 5px 20px !important;
}

.nav-link-icon {
  margin-right: 10px;
  color: var(--green);
}
.nav-link-icon-blue {
  margin-right: 10px;
  color: var(--blue);
}
/* profile Navbar */
/* Styles */
.profile-nav-container {
  height: 70px;
  display: flex;
  background-color: var(--white);
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
  box-shadow: var(--box-shadow);
  position: fixed;
}
.profile-nav-container .profile-nav-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* padding: 0.7rem; */
}
.profile-user-name {
  display: flex;
  padding: 10px;
  align-items: center;
  line-height: 1;
}
.profile-user-name .profile-name .welcome {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* line-height: 15px; */
  color: #bebebe;
  display: flex;
  align-items: center;
}
.profile-name {
  padding: 0px 10px;
}
.profile-username {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  /* line-height: 25px; */
  color: #152c07;
  display: flex;
  align-items: center;
}

.profile-hamburger {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  color: #152c07;
}
.mobile-menu-items .burger-container {
  padding: 20px;
}
.mobile-menu-items .burger {
  width: 25px;
  height: 2px;
  background: #000000;
  margin-bottom: 5px;
}

.profile-picture {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--light-blue);
  cursor: pointer;
}

.profile-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* nav item  */

.nav-menu-container {
  /* z-index: 100;
		position: relative;
		background-color: #FFE5B4;
		backdrop-filter: blur(5px); */

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(15px);
}
.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.2rem 0;
}
.nav-menu li {
  text-decoration: none;
  list-style: none;
  padding: 0.5rem 1.2rem;
  border-bottom: 1px solid #bebebe;
}
.nav-menu li:hover {
  background-color: #152c07;
}
/* wallet */
.wallet-container {
  /* background-color: red; */
  height: 100vh;
}
a {
  text-decoration: none;
  color: #ffffff;
}
.wallet-row {
  width: 90%;
  margin: 0 auto;
}

/* stlying for the board component  */
.board-container {
  padding-top: 5.8rem;
  color: #ffffff;
}
.board-row {
  background-color: #0b5ba1;
  background-image: url("../../images/walletBG.png");
  background-position: right 90% bottom 0%;
  background-repeat: no-repeat;
  height: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 90%;
  margin: 0 auto;
}
.board-row .board .board-main-account {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}
.board-row .board-indicator {
  background-color: #ffffff;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 0.6rem;
  color: #0b5ba1;
  border-radius: 10px;
}

/* stlying for the board component  */

/* stlying for the transaction component  */
.transactions-container {
  padding-top: 2.5rem;
  width: 90%;
  margin: 0 auto;
}
.transactions-row {
  display: flex;
  justify-content: space-between;
}
.fund-wallet,
.withdraw {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 16px 32px;
  gap: 12px;
  background: #0b5ba1;
}

.transactions-row a {
  color: var(--white);

  &:hover {
    color: var(--white);
  }
}
.account-card-container {
  margin-top: 3rem;
}
.account-card-row {
  width: 90%;
  margin: 0 auto;
}
.card-item {
  background: #ffffff;
  margin-bottom: 15px;
  border-radius: 5px;
  /* height: 68px; */
  padding: 17px 15px;
}
.account-card-inner {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.account-dollar-bal,
.account-btc-bal {
  /* padding-right: 1.2rem; */
}

.referral-card-container {
  width: 90%;
  margin: 0 auto;
}
.referral-card-row {
  display: flex;
  justify-content: space-between;
}

.referral-card-inner {
  padding: 10px 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: center;
}

.status-dot {
  color: var(--red);
  font-size: 30px;
  margin-left: 5px;
  margin-top: -20px;
  /* display: none; */
}
.status-dot-text {
  color: var(--red);
  margin-left: 5px;
  font-size: 12px;
  /* display: none; */
}
.inactive-box {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
/* AccountCardItem component styling here */
/* } */
@media only screen and (min-device-width: 500px) {
  .user-profile-container {
    width: 500px;
    margin: 0 auto;
  }
  .profile-nav-container {
    width: 500px;
    margin: 0 auto;
  }
}
