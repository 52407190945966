/* @media only screen and (min-device-width: 320px) and (max-device-width: 480px) { */
/* deposit component styling starts */
.deposit-row,
.withdraw-row {
  padding: 0 1.2rem 0;
}
.deposit-header {
  text-align: center;
  position: relative;
}
.withdraw-row {
  background-image: url(../../../assets/images/bg1.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: 190% 100%;
  height: 100vh;
  padding-top: 20px;
}

.di-card {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.deposit-form {
  width: 100%;
  margin: 0 auto;
  background-image: url(../../../assets/images/bg1.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: 190% 100%;
  height: 100vh;
  padding: 16px;
}
.deposit-form-item,
.withdraw-form-item {
  margin-bottom: 1rem;
}
.deposit-rate {
  /* padding: 1.3rem; */
}
.deposit-rate p {
  margin-bottom: 4px;
}
.deposit-rate span {
  font-weight: 600;
  margin-left: 4px;
}
.deposit-form-item input {
  width: 100%;
  background: #ffffff;
  outline: none;
  border: 0.5px solid #ece5c7;
  box-shadow: 0px 2px 10px rgb(51 51 51 / 10%);
  border-radius: 3px;
  height: 42px;
}

.deposit-form-item2 {
  display: flex;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.deposit-form-item label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #646fd4;
}
input.readonly {
  background-color: #d5d8b5;
  padding-left: 1.2rem;
  font-family: "Inter", sans-serif;
}
.deposit-form-item .required {
  color: #f32424;
  font-size: 10px;
}

.deposit-next {
  width: 100%;
}
.deposit-form .deposit-next,
.withdraw-btn {
  padding: 11px 0px;
  text-align: center;
  background: #0b5ba1;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 4rem;
}
.deposit-form a,
.withdraw-form a {
  text-decoration: none;
  color: #ffffff;
}
/* deposit component styling ends */

/* deposit2 component styling starts */
#deposit-form-item-1 {
  position: relative;
}
.wallet-address {
  color: #a2b38b;
}
.deposit-form .copy {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 0.1s ease;
  color: #4b7be5;
  padding: 5px;
  background-color: #eeeeee;
}
.deposit-form-item-1 {
  position: relative;
}
.deposit-form-item-1 p {
  padding: 5px;
  color: #a0bcc2;
  word-wrap: break-word;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.tooltip {
  color: #8bdb81;
  font-weight: 700;
}
.tooltip.appear {
  animation: appear 1s ease;
}
.submit {
  margin-top: 2rem;
}
/* deposit component styling ends */

/* withdraw component styling starts */
.withdraw-form-item label {
  font-weight: 600;
  color: #1b2430;
  transition: all 0.3s;
}
.full-width {
  width: 100%;
}

.img-box-2 {
  width: 250px;
  height: 250px;
  /* overflow: hidden; */
  margin-top: 20px;
  cursor: pointer;
}

.img-box-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.info-deposit {
  margin-top: 20px;
}

.info-deposit > p {
  font-size: 12px;
  font-weight: 100;
  text-align: left;
  margin-bottom: 5px;
}
.info-deposit > p::first-letter {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
}

.root-react-component-countdown-timer {
  position: absolute;
  right: 1px;
  top: -25px;
  color: red;
}
.count {
  color: red !important;
}

.qr-code {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.qr-code-box {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.modal-body-content {
  padding: 15px;
  text-align: center;
}

.modal-body-content > h3 {
  font-size: 16px;
  font-weight: 600;
}
.modal-body-content > img {
  width: 80px;
  height: 80px;
}
.modal-body-content > p {
  text-align: center;
  margin: 15px 0;
}
.modal-body-content > button {
  background-color: #00d257;
  color: var(--white);
  padding: 5px 10px;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 20px;
}
/* withdraw component styling ends */
/* } */

@media only screen and (min-device-width: 500px) {
  .deposit-row,
  .withdraw-row {
    width: 500px;
    margin: 0 auto;
  }
}
