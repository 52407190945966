* {
  border: 0;
  padding: 0;
  box-sizing: border-box;
  outline: transparent;
  text-decoration: none !important;
  transition: 0.3s all;
  margin: 0;
  font-family: "Quicksand", sans-serif;
}

body{
  background-color: #f7f7f7;
  padding: 0 !important;
  overflow: visible !important;
}
:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
  --green: rgb(93, 176, 117);
  --light-green: #43f6ae;
  --red: #c10000;
  --white: #ffffff;
  --main-background: rgba(243, 243, 243, 1);
  --border: rgba(217, 217, 217, 1);
  --border-bottom-color: rgb(229, 231, 235);
  --box-shadow: 0px 1px 5px #888;
  --box-shadow-sm: 0 0 #000, 0 0 #000, 0 0 #000, 0 0 #000,
    0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --light-blue: rgb(96 165 250);
  --blue: #0b5ba1;
}
/* import google fonts from fonts file */
@import url("./Fonts.css");
/* Media queries styles */
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}
